import { Button, Heading, Img, Text } from 'src/components/designsystem'
import NoServerSideRendering from 'src/components/utils/NoServerSideRendering'
import { Dispatch, SetStateAction, useState } from 'react'
import { TileModal } from 'src/components/shared/TileModal'
import { useCompany } from 'src/data/use-company'
import { registerWithNotify } from 'src/utils/push-notifications/register-with-notify'
import getCurrentGroupId from 'src/utils/push-notifications/group-id/get-current-group-id'
import { useAuth } from 'src/auth'
import { useNotificationGroupIdStore } from 'src/components/layout/header-buttons/NotificationHistoryHeaderButton'

export default function NotificationEnrollment() {
  const [showModal, setShowModal] = useState(true)

  return (
    <NoServerSideRendering>
      {showModal && (
        <TileModal>
          <MainImage />
          <Title />
          <Body />
          <EnableButton setShowModal={setShowModal} />
          <ContinueButton setShowModal={setShowModal} />
        </TileModal>
      )}
    </NoServerSideRendering>
  )
}

function MainImage() {
  return (
    <Img
      src="/img/illustrations/notifications-onboard.png"
      alt="NotificationsOnboard"
      marginBottom="1.5rem"
      width={['225px', null, '300px']}
    />
  )
}

function Title() {
  return (
    <Heading type="h3" fontSize="2rem" textAlign="center" fontWeight="700" marginBottom="1.5rem">
      In the Know
    </Heading>
  )
}

function Body() {
  const company = useCompany()
  const companyName = company?.company_name

  return (
    <Text textAlign="center" marginBottom="1.5rem">
      Notifications from {companyName} ensure you&apos;re up to date with your agribusiness, with
      price updates, changes to hours of operations and&nbsp;more.
      <br />
      <br />
      Fast and simple messages from us to&nbsp;you.
    </Text>
  )
}

function EnableButton({ setShowModal }: Readonly<ButtonProps>) {
  const { authenticated } = useAuth()
  const { setNotificationGroupId } = useNotificationGroupIdStore()

  const onClick = async () => {
    const userGroupId = await getCurrentGroupId(authenticated)
    setNotificationGroupId(userGroupId)

    registerWithNotify(userGroupId)
      .then(() => {
        setShowModal(false)
      })
      .catch(() => {
        setShowModal(false)
      })
  }

  return (
    <Button variant="primary" size="lg" marginBottom="1rem" onClick={onClick}>
      Manage notifications
    </Button>
  )
}

function ContinueButton({ setShowModal }: Readonly<ButtonProps>) {
  const onClick = () => {
    setShowModal(false)
  }

  return (
    <Button variant="link" onClick={onClick} p="16px">
      Ask again later
    </Button>
  )
}

interface ButtonProps {
  setShowModal: Dispatch<SetStateAction<boolean>>
}
